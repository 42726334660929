import { API_ENVS } from '@rc-d2c/context-manager';

export const getCurrentEnv = () => {
  const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;
  switch (environment) {
    case 'local':
    case 'int':
      return API_ENVS.DEV;
    case 'uat':
      return API_ENVS.UAT;
    default:
      return API_ENVS.STG;
  }
};
